<template>
  <div>
    <header
      id="appHeader"
      ref="header"
      class="sc-header"
    >
      <div class="sc-header__container">
        <a :href="mainUrl" class="sc-header__logo">
          <img
            :src="themeImages.logo"
            alt=""
            class="sc-header__logo-img"
          >
        </a>
        <div class="sc-header__controls">
          <a
            v-if="!isAuthorized"
            :href="`${themeUrls.Main}/login`"
            class="sc-header__button"
          >
            <Icon name="user-2" size="xxl"></Icon>
          </a>
          <template v-else>
            <div class="sc-header__personal">
              <Button
                variant="clear"
                shape="circle"
                ref="avatar"
              >
                <Avatar
                  :username="user && user.fullName"
                  :img="user && user.avatarSmallUrl"
                />
              </Button>
              <Dropdown
                  trigger="avatar"
                  listeners="hover click"
                  placement="bottom-end"
                  strategy="fixed"
                >
                  <template slot="items">
                    <div
                      class="base-dropdown__item"
                      v-for="(profileItem, index) in profileNav"
                      v-show="!profileItem.hidden"
                      :key="index"
                    >
                      <div v-if="profileItem.isDivider" class="divider"></div>
                      <a v-else class="base-dropdown__link" :href="profileItem.link" >
                        <Icon class="base-dropdown__icon" :name='profileItem.icon'></Icon>
                        <span class="base-dropdown__text">{{ profileItem.name }}</span>
                      </a>
                    </div>
                    <div class="divider"></div>
                    <div class="base-dropdown__item">
                      <a :href="`${themeUrls.Main}/Auth/LogOff`" class="base-dropdown__link">
                        <Icon class="base-dropdown__icon" name="logout"></Icon>
                        <span class="base-dropdown__text">{{ $t('profile.sign_out') }}</span>
                      </a>
                    </div>
                  </template>
                </Dropdown>
            </div>
            <!--a
              href="/"
              class="sc-header__button"
            >
              <Icon name="shopping-bag-2" size="xxl"></Icon>
              <span class="sc-header__button-dot"></span>
            </a-->
          </template>
          <div class="sc-header__lang">
            <button
              ref="lang"
              class="sc-header__lang-button"
            >
              {{ $lang }}
            </button>
            <Dropdown
              trigger="lang"
              listeners="hover click"
              placement="bottom-end"
            >
              <template slot="items">
                <div
                  class="base-dropdown__item"
                  v-for="item in filteredLanguages"
                  :key="item.id"
                  v-show="item.id !== $lang"
                >
                  <a
                    class="base-dropdown__link"
                    href="#"
                    @click.prevent="changeLang(item.id)"
                  >
                    <span class="base-dropdown__icon flag-img">
                      <img :src="require(`./img/${item.id}.jpg`)" :alt="item.id">
                    </span>
                    <span class="base-dropdown__text">{{ item.text }}</span>
                  </a>
                </div>
              </template>
            </Dropdown>
          </div>
          <button
            class="sc-header__toggle"
            :class="{ 'sc-header__toggle_open' : mobileNavOpened }"
            @click="toggleMenu"
          ></button>
        </div>
      </div>
    </header>
    <Nav
      v-model="mobileNavOpened"
      :menu="menu"
      :social="social"
      :style="stylePositionNav"
    ></Nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { setCulture } from '@/utils';
import Button from '@rr-component-library/button/src/main';
import Dropdown from '@/components/Dropdown/Dropdown.vue';
import Avatar from './Avatar.vue';
import Nav from './Nav.vue';
import ru from '../locales/ru.json';
import en from '../locales/en.json';
import dataTenant from '../data.json';

let themeUrls = null;

export default {
  name: 'SteelcharacterHeader',
  i18n: {
    messages: {
      ru,
      en,
    },
  },
  components: {
    Avatar,
    Button,
    Dropdown,
    Nav,
  },
  data() {
    return {
      mobileNavOpened: false,
      languages: [
        {
          id: 'ru',
          text: 'Русский',
        },
        {
          id: 'en',
          text: 'English',
        },
      ],
      profileNav: [
        {
          name: this.$t('profile.profile'),
          icon: 'user',
          link: `${themeUrls.Main}/Account`,
        },
        {
          name: this.$t('profile.registrations'),
          icon: 'badge',
          link: `${themeUrls.Main}/Account/RegistrationList`,
        },
        {
          name: this.$t('profile.orders'),
          icon: 'shopping-bag',
          link: `${themeUrls.Main}/Account/OrderList`,
        },
        {
          name: this.$t('profile.teams'),
          icon: 'team',
          link: `${themeUrls.Main}/Account/MyTeams`,
        },
      ],
      menu: [
        {
          text: this.$t('nav.schedule'),
          href: `${themeUrls.Events}/events`,
        },
        // {
        //   text: this.$t('nav.about'),
        //   href: dataTenant.links.menu.about,
        // },
        {
          text: this.$t('nav.obstacles'),
          href: dataTenant.links.menu.obstacles,
        },
        {
          text: this.$t('nav.photo'),
          href: dataTenant.links.menu.photo,
        },
        {
          text: this.$t('nav.news'),
          href: dataTenant.links.menu.news,
        },
        // {
        //   text: this.$t('nav.partners'),
        //   href: dataTenant.links.menu.partners,
        // },
        // {
        //   text: this.$t('nav.corporative'),
        //   href: dataTenant.links.menu.corporative,
        // },
        {
          text: this.$t('nav.contacts'),
          href: dataTenant.links.menu.contacts,
        },
      ],
      social: [
        {
          text: this.$t('social.vkontakte'),
          href: dataTenant.links.social.vkontakte,
        },
        // {
        //   text: this.$t('social.odnoklassniki'),
        //   href: dataTenant.links.social.odnoklassniki,
        // },
        {
          text: this.$t('social.youtube'),
          href: dataTenant.links.social.youtube,
        },
      ],
      mainUrl: dataTenant.links.main,
    };
  },
  computed: {
    ...mapGetters('settings', ['theme', 'themeImages', 'themeUrls', 'themeSettings']),
    ...mapGetters('user', ['user', 'isAuthorized']),
    stylePositionNav() {
      const { header } = this.$refs;
      if (this.mobileNavOpened && header) {
        const offset = header.offsetHeight - window.pageYOffset;
        return {
          top: `${offset}px`,
          height: `calc(100vh - ${offset}px)`,
        };
      }
      return {};
    },
    filteredLanguages() {
      return this.languages.filter((item) => this.themeSettings.languages.includes(item.id));
    },
  },
  methods: {
    toggleMenu() {
      this.mobileNavOpened = !this.mobileNavOpened;
    },
    changeLang(lang) {
      setCulture(lang, this.themeUrls.Main);
    },
  },
  beforeCreate() {
    themeUrls = this.$store.getters['settings/themeUrls'];
  },
};
</script>

<style lang="scss" scoped>
@import "./Header";

.flag-img {
  display: flex;
  align-items: center;
  width: 22px;

  img {
    flex: 0 0 auto;
    display: block;
    width: 100%;
    border: 1px solid #e8e6e8;
  }

  + .base-dropdown__text {
    padding-left: 32px;
  }
}
</style>
