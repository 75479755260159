<template>
  <transition
    name="nav-transition"
    tag="div"
  >
    <div
      v-show="isOpened"
      class="sc-nav"
    >
      <div class="sc-nav__container">
        <nav class="sc-nav__list">
          <a
            v-for="(itemMenu, indexMenu) in menu"
            :key="indexMenu"
            :href="itemMenu.href"
            class="sc-nav__link"
            :class="{ 'sc-nav__link_current': itemMenu.active }"
          >
            <span class="sc-nav__link-arrow sc-nav__link-arrow_current">
              <Icon name="arrow-stroke"></Icon>
            </span>
            {{ itemMenu.text }}
             <Icon class="sc-nav__link-arrow sc-nav__link-arrow_hover" name="arrow-stroke"></Icon>
          </a>
        </nav>
        <div class="sc-nav__social">
          <div
            v-for="(itemSocial, indexSocial) in social"
            :key="indexSocial"
            class="sc-nav__social-item"
          >
            <a
              :href="itemSocial.href"
              target="_blank"
              rel="noopener"
              class="sc-nav__social-link"
            >
              {{ itemSocial.text }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'SteelcharacterNav',
  model: {
    prop: 'isOpened',
    event: 'change',
  },
  props: {
    isOpened: Boolean,
    menu: {
      type: Array,
      default: () => [],
    },
    social: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    isOpened: {
      handler(val) {
        document.body.style.overflow = val ? 'hidden' : '';
      },
      immediate: true,
    },
  },
  methods: {
    open() {
      this.$emit('change', true);
    },
    close() {
      this.$emit('change', false);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "./Nav";
</style>
