<template>
  <div class="sc-avatar">
    <div
      class="sc-avatar__img"
      :style="{ backgroundImage: img && `url(${img})` }"
    >
      <svg v-if="!img && !initials && !username" viewBox="0 0 32 33" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.2725 25.1325C21.185 24.685 19.84 24.1075 19.84 23.66V20.78C21.44 19.565 22.4 17.645 22.4 15.66V11.82C22.4 8.30001 19.52 5.42001 16 5.42001C12.48 5.42001 9.60001 8.30001 9.60001 11.82V15.66C9.60001 17.645 10.56 19.6275 12.16 20.78V23.66C12.16 24.045 10.815 24.62 9.72751 25.1325C7.10501 26.22 3.20001 27.885 3.20001 32.3V32.94H28.8V32.3C28.8 27.885 24.895 26.22 22.2725 25.1325Z" />
      </svg>
    </div>
    <div class="sc-avatar__text">
        {{ initials || initialsFromName }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SteelcharacterAvatar',
  props: {
    username: String,
    initials: String,
    img: String,
  },
  computed: {
    initialsFromName() {
      if (!this.username) {
        return null;
      }
      const name = this.username.trim().split(' ');
      return name[0][0];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./Avatar";
</style>
